import React, { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Action } from 'typescript-fsa';

import { actions } from 'actions';
import { formatMoney, formatNumber } from 'common/numbers';
import {
  IColumn,
  IRow,
  SortOrder,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { ClickableCellConfig } from 'components/UI/common/TypedTable/renderers/ClickableCell';

import {
  OpenRevBiDrilldownModal,
  REV_BI_DEALS_MODAL_SCHEME,
} from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import {
  getDrilldownUrlQueryParams,
  handleOpenCustomObjectModal,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { OPPORTUNITY_SPLIT } from 'components/dashboard/Metrics/constants';
import {
  BIMetricSimple,
  DrillDownParams,
  FormulaMetricValuesAsChartValue,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';
import { fetchApi } from 'utils/network';
import DeltaTooltip from 'components/dashboard/Metrics/Widget/Table/helpers/DeltaTooltip';

export const useTableColumnBuilder = (
  drilldownTableSettings: any,
  clickHandler: (id: IRow, drilldownTableSettings: any) => void,
  changesSinceSelected: string | false | undefined
) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  const subValue = changesSinceSelected
    ? {
        badge: {
          type: 'subtraction',
          clickable: false,
          relative_fields: ['numberValue', 'numberDeltaValue'],
          valueTypeField: 'badgeValueType',
          showPlaceholderIfNoValue: false,
          fieldParser: (value: ValueProp) => {
            // For deltas '-' is a valid value, but we need to return 0 for it
            // so the subtraction will be correct
            if (value === '-') {
              return 0;
            }
            return value;
          },
          formatter: (value: ValueProp, valueType: ColumnTypes) => {
            if (typeof value === 'undefined') {
              return;
            }
            return getFormattedValue(
              Math.abs(value as number),
              valueType,
              companyCurrencyCode
            );
          },

          tooltip: {
            position: `top right`,
            renderer: (column: IColumn, row: IRow) => (
              <DeltaTooltip
                currentValue={
                  getFormattedValue(
                    row.numberValue as string,
                    row.badgeValueType as ColumnTypes,
                    companyCurrencyCode
                  ) as string
                }
                previousValue={
                  getFormattedValue(
                    row.numberDeltaValue as string,
                    row.badgeValueType as ColumnTypes,
                    companyCurrencyCode
                  ) as string
                }
                changesSince={changesSinceSelected}
              />
            ),
          },
        },
      }
    : undefined;

  const columns: IColumn[] = React.useMemo(
    () => [
      {
        id: 'variable',
        label: 'Metric',
        field: 'metricName',
        type: ColumnTypes.TEXT,
        sort_order: SortOrder.ASCENDING,
        config: {},
      } as IColumn,
      {
        id: 'value',
        label: 'Value',
        field: 'value',
        type: ColumnTypes.MONEY,
        sort_order: SortOrder.ASCENDING,
        drilldownTableSettings,
        config: {
          click(column: IColumn, row: IRow) {
            clickHandler(row, column.drilldownTableSettings);
          },
          subValue,
        },
        align: 'right' as 'right',
        format: 'number',
      } as IColumn,
    ],
    [drilldownTableSettings, clickHandler, subValue]
  );

  return { columns };
};

const getFormattedValue = (
  value: string | number | undefined,
  type: string | undefined,
  companyCurrencyCode: string
) => {
  if (value === undefined) {
    return '-';
  }
  switch (type) {
    case 'money':
      return formatMoney(companyCurrencyCode, value as number);
    case 'number':
      return formatNumber(value as number);
    default:
      return value;
  }
};

export const useTableData = (
  metricValues: FormulaMetricValuesAsChartValue[],
  metrics: BIMetricSimple[],
  drilldownParams: DrillDownParams
) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  const tableData = React.useMemo(() => {
    if (typeof metricValues !== 'undefined' && metrics.length > 0) {
      return metricValues.map((metric) => {
        const metricData = metrics.find((m) => m._id === metric.id);

        const metricValue = metric.value;
        const metricType = metricData?.column?.type;

        const formattedValue = getFormattedValue(
          metricValue,
          metricType,
          companyCurrencyCode
        );

        const metricDeltaValue = metric.deltaValue;

        return {
          id: metric.id,
          value: formattedValue,
          metricName: metric.metricName || metricData?.name,
          object: metricData?.object,
          drilldownParams: { ...drilldownParams, ...metricData }, // we need to pass the filters of the metric.
          numberValue: metricValue,
          numberDeltaValue: metricDeltaValue,
          badgeValueType:
            metricType === ColumnTypes.MONEY
              ? ColumnTypes.MONEY
              : ColumnTypes.NUMBER,
        };
      });
    } else {
      return [];
    }
  }, [metricValues, metrics, drilldownParams, companyCurrencyCode]);

  return { tableData };
};

export const useFetchMetrics = (
  metricValues: FormulaMetricValuesAsChartValue[]
) => {
  const [simpleMetricsUsed, setSimpleMetricsUsed] = React.useState<
    BIMetricSimple[]
  >([]);

  React.useEffect(() => {
    const simpleMetrics: BIMetricSimple[] = [];
    if (typeof metricValues === 'undefined') {
      return;
    }

    const metricsDataAsArray = metricValues.map(async (metric) => {
      if (typeof metric.id === 'string') {
        return await fetchApi<string, BIMetricSimple>({
          url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${metric.id}`,
          queryMethod: 'get',
          setData: (metricData: BIMetricSimple) => {
            simpleMetrics.push(metricData);
          },
          setError: (error: string | null) => {
            toast.error(
              `Failed to load metric data ${metric.metricName}: ${error}`
            );
          },
        });
      }
    });

    Promise.all(metricsDataAsArray).then(() =>
      setSimpleMetricsUsed(simpleMetrics)
    );
  }, [metricValues]);

  return { simpleMetricsUsed };
};

const getApiPath = (object: string) => {
  switch (object) {
    case 'opportunity':
      return 'deals';
    case 'account':
      return 'accounts';
    case 'calendar_event':
      return 'events';
    case 'email':
      return 'emails';
    default:
      return null;
  }
};

const getModalPath = (object: string) => {
  switch (object) {
    case 'opportunity':
      return '/deals';
    case 'account':
      return '/accounts';
    case 'calendar_event':
      return '/events';
    case 'email':
      return '/emails';
    default:
      return '/deals';
  }
};

export const useOpenModalByMetricCallback = (
  dispatch: Dispatch<Action<PersistQueryParams>>,
  openRevBiDrilldownModal?: OpenRevBiDrilldownModal,
  changeInterval?: string
) => {
  const handleMetricClick = React.useCallback(
    (row: any, drilldownTableConfig: any) => {
      if (
        Object.keys(drilldownTableConfig ?? {}).includes(row.object) &&
        openRevBiDrilldownModal &&
        drilldownTableConfig
      ) {
        const tableConfig = drilldownTableConfig[row.object];

        handleOpenCustomObjectModal(
          row.drilldownParams,
          row.object,
          row.metricName,
          tableConfig,
          openRevBiDrilldownModal
        );

        return;
      }

      if (row.object === OPPORTUNITY_SPLIT) {
        openModal({
          scheme: '/opportunity-split',
          persistParams: {
            apiUrl: `/api/settings/tables/opportunity_split/RevbiOpportunitySplit`,
            filters: [],
            title: 'metricName',
            showTotalAmount: false,
            metricObject: row.object,
            metricName: row.metricName,
            drilldownParams: row.drilldownParams,
          },
          persistor: (params: PersistQueryParams) => {
            dispatch(actions.ui.modal.persist(params));
          },
        });

        return;
      }

      const drilldownUrlQueryParams = getDrilldownUrlQueryParams(
        row.drilldownParams
      );

      fetchApi<any, { data: string[] }>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${row.object}${drilldownUrlQueryParams}`,
        queryMethod: 'post',
        queryParams: row.drilldownParams,
        setData: (result) => {
          const data = result.data ?? [];

          const apiPath = getApiPath(row.object as string);

          if (data.length && apiPath) {
            const popupTableParams = {
              apiUrl: `/api/data/${apiPath}/`,
              tableConfigName: 'RevbiOpportunityTable',
              context: '',
              deltaOverPeriod: 0,
              filters: JSON.stringify({
                ids: data,
                skip_business_validation: true,
              }),
              title: '',
              origin: 'revbi',
            };

            const scheme = getModalPath(row.object as string);

            if (scheme === '/deals' && openRevBiDrilldownModal) {
              openRevBiDrilldownModal({
                scheme: REV_BI_DEALS_MODAL_SCHEME,
                props: {
                  ids: data.map((id) => id),
                  title: '',
                  skip_business_validation:
                    !row.drilldownParams.business_type_name,
                  ...(row.drilldownParams.business_type_name && {
                    business_type_name: row.drilldownParams.business_type_name,
                  }),
                  changeInterval,
                },
              });
            } else {
              openModal({
                scheme,
                persistParams: popupTableParams,
                persistor: (params: PersistQueryParams) => {
                  dispatch(actions.ui.modal.persist(params));
                },
              });
            }
          }
        },
        setError: (error: string | null) => {
          toast.error('Failed to load table data:' + error);
        },
      });
    },
    []
  );

  return { handleMetricClick };
};
